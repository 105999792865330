import Fetcher from '../../Shared/Common/Fetcher';
import { mutate } from 'swr';

let abortController: AbortController = new AbortController();

export async function CheckItem(
  channelId: string,
  languageRoute: string,
  code: string,
  setDisabled: (value: boolean) => void
) {
  const url = `/api/${
    languageRoute || 'en' || 'en'
  }/batchorder/CheckItem?code=${code}`;
  setDisabled(true);
  abortController.abort();
  abortController = new AbortController();
  const signal = abortController.signal;
  const litiumContext = JSON.stringify({
    channelSystemId: channelId,
  });
  return await Fetcher<any, any>(
    url,
    signal,
    (data, resolve) => {
      resolve(data);
      setDisabled(false);
    },
    litiumContext
  );
}

export async function CheckItems(
  channelId: string,
  languageRoute: string,
  codes: string[],
  setDisabled: (value: boolean) => void
) {
  let codesUrl = '';

  codes.map((code: string, index: number) => {
    if (index < codes.length - 1) {
      codesUrl = `${codesUrl}codes=${code}&`;
    } else {
      codesUrl = `${codesUrl}codes=${code}`;
    }
  });

  const url = `/api/${languageRoute || 'en'}/batchorder/CheckItems?${codesUrl}`;
  setDisabled(true);
  abortController.abort();
  abortController = new AbortController();
  const signal = abortController.signal;
  const litiumContext = JSON.stringify({
    channelSystemId: channelId,
  });
  return await Fetcher<any, any>(
    url,
    signal,
    (data, resolve) => {
      resolve(data);
      setDisabled(false);
    },
    litiumContext
  );
}

export async function AddItemToCart(
  channelId: string,
  requestToken: string,
  languageRoute: string,
  code: string,
  quantity: number,
  setIsLoading: (value: boolean) => void
) {
  setIsLoading(true);
  const res = await fetch(
    `/api/${
      languageRoute || 'en'
    }/batchorder/AddItemToCart?code=${code}&quantity=${quantity}`,
    {
      method: 'POST',
      headers: {
        'litium-request-context': JSON.stringify({
          channelSystemId: channelId,
        }),
        RequestVerificationToken: requestToken,
      },
    }
  );

  if (res.ok) {
    const cartUrl = `/api/${languageRoute || 'en'}/Cart/`;
    const { cart } = await res.json();
    mutate(`${cartUrl}GetCart`, cart, false);

    setIsLoading(false);
    return true;
  }

  setIsLoading(false);
  return false;
}

export async function AddItemsToCart(
  channelId: string,
  languageRoute: string,
  data: any
) {
  const res = await fetch(
    `/api/${languageRoute || 'en'}/batchorder/AddItemsToCart`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'litium-request-context': JSON.stringify({
          channelSystemId: channelId,
        }),
      },
      body: JSON.stringify(data),
    }
  );

  if (res.ok) {
    const cartUrl = `/api/${languageRoute || 'en'}/Cart/`;
    const { cart } = await res.json();
    mutate(`${cartUrl}GetCart`, cart, false);

    return true;
  }

  return false;
}
