import { useEffect, useState } from 'react';
import VariationModel from '../../KexVariation/Models/VariationModel.interface';
import { styled } from '../../stitches.config';
import { TrashIcon, PlusIcon, MinusIcon } from '../../Atoms/Icons';
import { SetQuantity, RemoveFromCart } from '../../Pages/CartPage/Cart';
import { useAppSettingsData } from '../../Shared/Providers/AppSettingsProvider';
import { useTranslationData } from '../../Shared/Providers/TranslationProvider';

type PropTypes = {
  lineItem: VariationModel;
};

function BatchProductCard({ lineItem }: PropTypes) {
  const { languageRoute } = useAppSettingsData();

  const {
    'productItem/size': size,
    'productItem/amount': amount,
    'productItem/remove': removeText,
  } = useTranslationData();

  const [inputQuantity, setInputQuantity] = useState<string>(
    lineItem.quantity.toString()
  );

  const onQuantityMinus = () => {
    setInputQuantity((Number(inputQuantity) - 1).toString());
    SetQuantity(lineItem.articleNumber, Number(Number(inputQuantity) - 1));
  };

  const onQuantityPlus = () => {
    setInputQuantity((Number(inputQuantity) + 1).toString());
    SetQuantity(lineItem.articleNumber, Number(Number(inputQuantity) + 1));
  };

  const onInputBlur = (numb: string) => {
    if (Number(numb) < 1) {
      setInputQuantity('1');
      SetQuantity(lineItem.articleNumber, 1);
    } else {
      setInputQuantity(numb);
      SetQuantity(lineItem.articleNumber, Number(numb));
    }
  };

  useEffect(() => {
    if (lineItem.quantity.toString() !== inputQuantity) {
      setInputQuantity(lineItem.quantity.toString());
    }
  }, [lineItem]);

  return (
    <Root>
      <ImageCell>
        <ImageAreaWrapper>
          <a href={lineItem.to}>
            <Image src={lineItem.image && lineItem.image.src} />
          </a>

          <Details>
            <TitleLink href={lineItem.to}>
              <Title>{lineItem.name}</Title>
            </TitleLink>
            <DetailText>{lineItem.code}</DetailText>
            <DetailText>
              {size}: {lineItem.size}
            </DetailText>
            <DetailText>
              {amount}: {lineItem.quantity}
            </DetailText>
            <Actions>
              <ActionButton
                onClick={() =>
                  RemoveFromCart(lineItem.articleNumber, languageRoute)
                }
              >
                <Trash />
                <ButtonText>{removeText}</ButtonText>
              </ActionButton>
            </Actions>
          </Details>
        </ImageAreaWrapper>
      </ImageCell>

      <PriceCell>
        <QuantityWrapper>
          <>
            <MinusButton
              disabled={inputQuantity === '1'}
              onClick={onQuantityMinus}
            >
              <StyledMinusIcon />
            </MinusButton>
            <QuantityInput
              type="number"
              value={inputQuantity}
              onChange={(e: any) => setInputQuantity(e.target.value)}
              onBlur={(e: any) => onInputBlur(e.target.value)}
            />
            <button onClick={onQuantityPlus}>
              <StyledPlusIcon />
            </button>
          </>
        </QuantityWrapper>

        <Price>{lineItem.totalPrice}</Price>
      </PriceCell>
    </Root>
  );
}

const Root = styled(`div`, {
  display: 'flex',
  justifyContent: 'space-between',
});

const Actions = styled(`div`, {
  display: 'flex',
  mt: 'auto',
});

const ActionButton = styled(`div`, {
  display: 'flex',
  cursor: 'pointer',
  alignItems: 'center',
  '@mediaMinMedium': {
    mr: 4,
  },
  m: 0,
});

const ButtonText = styled(`span`, {
  fs: 6,
  mb: 0.25,
  '&:hover': {
    borderBottom: 'solid',
    borderBottomColor: '$Black',
    borderBottomWidth: '1px',
  },
});

const Trash = styled(TrashIcon, {
  w: 3.5,
  h: 4,
  mr: 1,
});

const TitleLink = styled(`a`, {
  mb: 2,
});

const Title = styled(`span`, {
  fs: 7,
  fontWeight: '$fw700',
  letterSpacing: '$ls07',
  color: '$primary',
});

const Details = styled(`div`, {
  display: 'flex',
  flexDirection: 'column',
  '@mediaMinMedium': {
    ml: 4,
  },
  ml: 2,
});

const DetailText = styled(`span`, {
  fs: 6,
  color: '$primary',
  mb: 1,
  '&:last-of-type': {
    mb: 2,
  },
});

const ImageCell = styled(`div`, {
  display: 'table-cell',
  width: '45%',
  py: 1,
});

const ImageAreaWrapper = styled(`div`, {
  display: 'flex',
});

const PriceCell = styled(`div`, {
  color: '$black',
  fs: 9,
  fontWeight: '$fw700',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  textAlign: 'end',
});

const Price = styled(`span`, {
  fs: 7,
  fontWeight: '$fw700',
  lineHeight: '$lh1',
  letterSpacing: '$ls06',
});

const Image = styled(`img`, {
  h: 30,
  w: 25,
  objectFit: 'contain',
});

const QuantityWrapper = styled(`div`, {
  display: 'flex',
  justifyContent: 'center',
  px: 3,
  h: 8,
  border: 'solid',
  bw: 0.25,
  borderColor: '$borderHoverPrimary',
});

const StyledPlusIcon = styled(PlusIcon, {
  h: 4.5,
  w: 4.5,
  fill: '$black',
});

const StyledMinusIcon = styled(MinusIcon, {
  h: 4.5,
  w: 4.5,
  fill: '$black',
});

const QuantityInput = styled(`input`, {
  backgroundColor: 'inherit',
  opacity: 0.7,
  maxW: 17.5,
  textAlign: 'center',
  MozAppearance: 'textfield', // hide number arrows Firefox
  '&::-webkit-outer-spin-button': {
    WebkitAppearance: 'none',
    m: 0,
  },
  '&::-webkit-inner-spin-button': {
    WebkitAppearance: 'none',
    m: 0,
  },
});

const MinusButton = styled(`button`, {
  '&:disabled': {
    cursor: 'default',
    opacity: 0.6,
  },
});

export default BatchProductCard;
