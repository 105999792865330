import React, { useEffect, useState } from 'react';
import { styled } from '../../stitches.config';
import { useKexInputValidation, ValidationParams } from './KexInputValidation';

type KexInputValidationType = {
  validation?: ValidationParams;
};

type KexInputType = KexInputValidationType & {
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
  onKeyDown?: (event: React.KeyboardEvent) => void;
  title: string;
  placeholder?: string;
  type?: string;
  name?: string;
  value?: string;
  isEmpty?: boolean;
  showLabel?: boolean;
};

const KexInput = ({
  title,
  placeholder,
  type = 'text',
  onChange,
  onBlur,
  onKeyDown,
  name,
  validation,
  isEmpty,
  value,
  showLabel = true,
}: KexInputType) => {
  const validationDispatch = useKexInputValidation();

  const [hasError, setHasError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [hasTouched, setHasTouched] = useState<boolean>(false);
  const [hasValue, setHasValue] = useState<boolean>(false);

  const doValidation = (currentValue: string) => {
    if (!!validation) {
      const errorMessage = validation.errorMessage || '';
      let validationError = false;

      if (validation.active !== undefined && !validation.active) {
        return;
      }

      if (validation.pattern) {
        if (!validation.pattern.test(currentValue)) {
          validationError = true;
        }
      } else if (validation.required) {
        if (!currentValue) {
          validationError = true;
        }
      } else if (validation.minLength) {
        if (
          !currentValue ||
          (currentValue && currentValue.length < validation.minLength)
        ) {
          validationError = true;
        }
      } else if (validation.maxLength) {
        if (currentValue && currentValue.length > validation.maxLength) {
          validationError = true;
        }
      }

      if (validationError) {
        setHasError(true);
        setErrorMessage(errorMessage);
      }

      setHasValue(!!currentValue);
    }
  };

  const onInputBlur = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const currentValue: string = e.currentTarget.value;

    doValidation(currentValue);
    onBlur && onBlur(e.currentTarget.value);
  };

  const onInputFocus = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (!!validation && validation.onTouched) {
      validation.onTouched(name); // TODO: Refactor? While it's okay to use callbacks since they was passed down, I don't like the fact
    }

    setHasError(false);
    setHasTouched(true);
  };

  useEffect(() => {
    validationDispatch({
      type: 'register',
      name: name,
      payload: {
        hasTouched: hasTouched,
        hasError: hasError,
        hasValue: hasValue,
      },
    });

    if (!!validation) {
      if (validation.backendValidation) {
        setHasError(true);
        setErrorMessage(validation.backendValidation.message);
      }
    }

    return () => {
      validationDispatch({ type: 'unregister', name: name, payload: {} });
    };
  }, [validationDispatch, name, hasTouched, hasError, hasValue, validation]);

  return (
    <InputGroup>
      {!isEmpty && showLabel && (
        <FormLabel css={hasError ? FormLabelError : {}}>
          {hasError ? errorMessage : title}
        </FormLabel>
      )}
      {type === 'textarea' ? (
        <FormTextArea
          rows={20}
          notEmpty={!isEmpty && showLabel}
          placeholder={placeholder || title}
          css={hasError ? FormInputError : {}}
          value={value}
          onChange={(e: { currentTarget: { value: string } }) => {
            onChange && onChange(e.currentTarget.value);
          }}
          onBlur={(
            e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
          ) => {
            onInputBlur(e);
          }}
          onKeyDown={(e: React.KeyboardEvent<Element>) => {
            onKeyDown && onKeyDown(e);
          }}
          onFocus={(
            e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
          ) => {
            onInputFocus(e);
          }}
          name={name}
        />
      ) : (
        <FormInput
          placeholder={placeholder || title}
          css={hasError ? FormInputError : {}}
          type={type}
          value={value}
          onChange={(e: { currentTarget: { value: string } }) => {
            onChange && onChange(e.currentTarget.value);
          }}
          onBlur={(
            e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
          ) => {
            onInputBlur(e);
          }}
          onKeyDown={(e: React.KeyboardEvent<Element>) => {
            onKeyDown && onKeyDown(e);
          }}
          onFocus={(
            e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
          ) => {
            onInputFocus(e);
          }}
          name={name}
        />
      )}
    </InputGroup>
  );
};

const InputGroup = styled('div', {
  position: 'relative',
  backgroundColor: '$white',
});

const FormLabel = styled('label', {
  fs: 5,
  fontWeight: '$fw700',
  lineHeight: '13px',
  position: 'absolute',
  color: 'inherit',
  mx: 3,
  px: 1.5,
  backgroundColor: 'inherit',
});

const FormInput = styled('input', {
  fs: 8,
  fontWeight: '$fw700',
  backgroundColor: 'inherit',
  w: '100%',
  h: 11,
  my: 2,
  px: 4,
  pt: 1,
  pb: 2,
  border: '1px solid $primary',
  '&:focus': {
    border: '1px solid $blue',
    outline: '1px solid $blue',
  },
  '&::placeholder': {
    color: '$grey500',
    fs: 5,
    fontWeight: '$fw400',
  },
  '@mediaMaxLarge': {
    w: '100%',
  },
});

const FormTextArea = styled('textarea', {
  h: 20,
  fs: 5,
  resize: 'none',
  br: 2.5,
  backgroundColor: 'inherit',
  w: '100%',
  my: 2,
  px: 3,
  border: '1px solid $grey100',
  '&:focus': {
    border: '1px solid $blue',
    outline: '1px solid $blue',
  },
  '&::placeholder': {
    color: '$grey500',
    fs: 5,
    fontWeight: '$fw400',
    '@mediaMinLarge': {
      fs: 7,
    },
  },
  variants: {
    notEmpty: {
      true: {
        pt: 3.5,
      },
    },
  },
  '@mediaMinLarge': {
    w: '100%',
    fs: 7,
    py: 6,
    pr: 8,
  },
});

const FormInputError = {
  border: '1px solid $errorPrimary',
};

const FormLabelError = {
  color: '$errorText',
};

export default styled(KexInput);
