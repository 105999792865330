import { useState } from 'react';
import { styled } from '../../stitches.config';
import { useTranslationData } from '../../Shared/Providers/TranslationProvider';
import Divider from '../../Atoms/Divider/Divider';
import MultipleBatchOrder from './MultipleBatchOrder';
import SingleBatchOrder from './SingleBatchOrder';
import { GetCart } from '../CartPage/Cart';
import VariationModel from '../../KexVariation/Models/VariationModel.interface';
import BatchProductCard from './BatchProductCard';

type TABS = 'single' | 'multiple';

function BatchOrderPage() {
  const [activeTab, setActiveTab] = useState<TABS>('single');
  const { cart } = GetCart();

  const {
    'batchOrder/heading': headingLabel,
    'batchOrder/subHeading': subHeadingLabel,
    'batchOrder/preamble': preambleLabel,
    'batchOrder/singleTab': singleTabLabel,
    'batchOrder/multipleTab': multipleTabLabel,
    'batchOrder/handleLineItems': handleLineItemsLabel,
  } = useTranslationData();

  return (
    <main>
      <BatchOrderPageWrapper>
        <Heading>{headingLabel}</Heading>
        <MinDivider />
        <SubHeadingArea>
          <SubHeading>{subHeadingLabel}</SubHeading>
          <TabList>
            <Tab
              data-active={activeTab === 'single'}
              onClick={() => setActiveTab('single')}
            >
              {singleTabLabel}
            </Tab>
            <Tab
              data-active={activeTab === 'multiple'}
              onClick={() => setActiveTab('multiple')}
            >
              {multipleTabLabel}
            </Tab>
          </TabList>
        </SubHeadingArea>
        <Preamble>{preambleLabel}</Preamble>
        {activeTab === 'single' ? <SingleBatchOrder /> : <MultipleBatchOrder />}
        {cart && cart.lineItems && cart.lineItems.length > 0 && (
          <ListItems>
            <LineItemHeading>
              {`${handleLineItemsLabel} ${
                cart.numberOfItems > 0 ? `- ${cart.numberOfItems}` : ''
              }`}
            </LineItemHeading>
            {cart.lineItems.map((lineItem: VariationModel) => (
              <>
                <BatchProductCard
                  key={lineItem.articleNumber}
                  lineItem={lineItem}
                />
              </>
            ))}
          </ListItems>
        )}
      </BatchOrderPageWrapper>
    </main>
  );
}

const BatchOrderPageWrapper = styled(`div`, {
  display: 'block',
  p: 7,
  width: '100%',
  maxW: '1024px',
  '@mediaMinLarge': {
    mx: 'auto',
    my: 12,
  },
});

const Heading = styled(`h1`, {
  fs: 16,
  fontWeight: 'lighter',
  lineHeight: '$lh2',
  letterSpacing: '$ls1625',
  '@mediaMinLarge': {
    fs: 22,
    letterSpacing: '$ls2',
  },
});

const MinDivider = styled(Divider, {
  w: 16,
  my: 4.5,
  '@mediaMaxSmall': {
    my: 4,
    mb: 4,
  },
});

const SubHeadingArea = styled(`div`, {
  mb: 4.5,
  '@mediaMinMedium': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});

const SubHeadingStyle = {
  fs: 10,
  fontWeght: '$fw300',
  lineHeght: '$lh2',
  letterSpacing: '$ls1625',
  mb: 4,
  '@mediaMinLarge': {
    fs: 13,
  },
};

const SubHeading = styled(`h2`, {
  ...SubHeadingStyle,
  '@mediaMinMedium': {
    mb: 0,
  },
});

const ListItems = styled(`div`, {
  mt: 4,
});

const LineItemHeading = styled(`h2`, {
  ...SubHeadingStyle,
  mb: 4,
});

const Preamble = styled(`p`, {
  fs: 8,
  fontWeight: 'lighter',
  lineHeight: '$lh175',
  letterSpacing: '$ls1',
  mb: 4.5,
});

const TabList = styled(`ul`, {
  backgroundColor: '$grey550',
  br: 1,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  p: 0.25,
  '@mediaMinMedium': {
    minW: 62.5,
    maxW: 85.25,
    flexDirection: 'row',
  },
  '@mediaMinLarge': {
    h: 8,
  },
});

const Tab = styled(`li`, {
  fs: 6,
  lineHeight: '$lh1',
  textAlign: 'center',
  letterSpacing: '$ls1',
  opacity: 0.9,
  zIndex: 1,
  flexGrow: 1,
  cursor: 'pointer',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  h: 8,
  w: '100%',
  '&:nth-child(n)[data-active="true"]': {
    backgroundColor: '$white',
    br: 1,
  },
  '@mediaMinLarge': {
    h: '100%',
  },
});

export default BatchOrderPage;
