import { useState } from 'react';
import useCurrentPage from '../../Shared/Hooks/useCurrentPage';
import { styled } from '../../stitches.config';
import { useAppSettingsData } from '../../Shared/Providers/AppSettingsProvider';
import { useTranslationData } from '../../Shared/Providers/TranslationProvider';
import PlusToggleIcon from '../../Atoms/Icons/PlusIcon';
import {
  ExclamationMarkIcon,
  LoadingCircle,
  PlusIcon,
  MinusIcon,
  CirclePlusIcon,
  AttentionIcon,
} from '../../Atoms/Icons';
import KexInput from '../../Shared/Input/KexInput';
import { KexInputValidation } from '../../Shared/Input/KexInputValidation';
import PageModelBase from '../../Shared/Models/PageModelBase.interface';
import {
  AddItemToCart,
  CheckItem,
} from '../../BatchOrderPage/Models/BatchOrder';

function SingleBatchOrder() {
  const [error, setError] = useState(false);
  const [validationMessage, setValidationMessage] = useState<string>('');
  const [btnEnabled, setBtnEnabled] = useState<boolean>(false);
  const [productExist, setProductExist] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [disabled, setDisabled] = useState(false);
  const [code, setCode] = useState<string>('');
  const [quantity, setQuantity] = useState<string>('1');
  const [addToCartErrorMessage, setErrorMessage] = useState<string>('');

  const { languageRoute, requestToken } = useAppSettingsData();

  const {
    'batchOrder/inputLabel': inputLabel,
    'batchOrder/inputPlaceholder': inputPlaceholder,
    'batchOrder/addItem': addItemLabel,
    'batchOrder/inputValidationRequired': inputValidationRequired,
    'batchOrder/errorMessage': errorMessage,
  } = useTranslationData();

  const { channelId } = useCurrentPage<PageModelBase>();
  const onInputsValid = () => setBtnEnabled(true);
  const onInputsInvalid = () => setBtnEnabled(false);
  const onQuantityMinus = () => setQuantity((Number(quantity) - 1).toString());
  const onQuantityPlus = () => setQuantity((Number(quantity) + 1).toString());

  const onInputBlur = (e: any) => {
    if (Number(e.target.value) < 1) {
      setQuantity('1');
    } else {
      setQuantity(e.target.value);
    }
  };

  const handleSetCode = (value: string) => {
    setProductExist(false);
    setErrorMessage('');
    setCode(value);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (disabled) return;

    await AddItemToCart(
      channelId,
      requestToken,
      languageRoute,
      code,
      parseInt(quantity),
      setIsLoading
    );
  };

  const handleCheckItem = async () => {
    if (code.length > 0) {
      setErrorMessage('');

      const res = await CheckItem(channelId, languageRoute, code, setDisabled);

      if (res) {
        setProductExist(true);
      } else {
        setProductExist(false);
        setErrorMessage(`${errorMessage.replace('{0}', code)}`);
      }
    }
  };

  const isNotValid = disabled || !btnEnabled || !productExist;

  return (
    <>
      <ArticleArea>
        <InputArea data-disabled={isLoading}>
          {error && (
            <ValidationMessage>
              <ExclamationMarkIcon css={IconStyleError} />{' '}
              <ErrorMessage>{validationMessage}</ErrorMessage>
            </ValidationMessage>
          )}
          <KexInputValidation
            onInputsValid={onInputsValid}
            onInputsInvalid={onInputsInvalid}
          >
            <StyledKexInput
              type="text"
              name="BatchOrderItem"
              title={inputLabel}
              placeholder={inputPlaceholder}
              value={code}
              onChange={handleSetCode}
              onBlur={handleCheckItem}
              validation={{
                required: true,
                errorMessage: inputValidationRequired,
              }}
            />
          </KexInputValidation>
        </InputArea>

        <AddToCartContainer data-disabled={isNotValid}>
          <QuantityWrapper>
            <>
              <MinusButton
                disabled={quantity === '1' || isNotValid}
                onClick={onQuantityMinus}
              >
                <StyledMinusIcon />
              </MinusButton>
              <QuantityInput
                type="number"
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
                onBlur={onInputBlur}
                disabled={isNotValid}
              />
              <button onClick={onQuantityPlus}>
                <StyledPlusIcon />
              </button>
            </>
          </QuantityWrapper>
          <AddToCartButton disabled={isNotValid} onClick={handleSubmit}>
            <>
              {isLoading ? (
                <StyledLoadingCircle css={iconCss} isLoading={isLoading} />
              ) : (
                <CirclePlusIcon css={iconCss} />
              )}
              {addItemLabel}
            </>
          </AddToCartButton>
        </AddToCartContainer>
      </ArticleArea>

      {addToCartErrorMessage && (
        <AddToCartErrorContainer>
          <AddToCartError>
            <AttentionIcon color="error" css={iconCss} />
            {addToCartErrorMessage}
          </AddToCartError>
        </AddToCartErrorContainer>
      )}
    </>
  );
}

const DisabledStyle = {
  '&:nth-child(n)[data-disabled="true"]': {
    opacity: 0.5,
    pointerEvents: 'none',
  },
};

const ValidationMessage = styled(`div`, {
  fs: 8,
  fontWeight: 'normal',
  lineHeight: '$lh1625',
  backGroundColor: '$errorPrimary',
  color: '$errorText',
  py: 6,
  px: 6,
  my: 6,
  display: 'flex',
});

const IconStyleError = {
  w: 4,
  h: 4,
  me: 2,
  mt: 1,
};

const StyledKexInput = styled(KexInput, {
  backgroundColor: '$textSecondary',
});

const ErrorMessage = styled(`p`, {
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: '0%',
});

const ArticleArea = styled(`div`, {
  display: 'flex',
  mb: 4.5,
  '@mediaMaxSmall': {
    mb: 4,
  },
});

const InputArea = styled(`div`, {
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: '0%',
  mr: 4,
});

const MinusButton = styled(`button`, {
  '&:disabled': {
    cursor: 'default',
    opacity: 0.6,
  },
});

const AddToCartContainer = styled(`div`, {
  display: 'flex',
  ...DisabledStyle,
});

const QuantityWrapper = styled(`div`, {
  display: 'flex',
  justifyContent: 'center',
  my: 2,
  mr: 4,
  px: 3,
  border: 'solid',
  bw: 0.25,
  borderColor: '$primary',
});

const StyledPlusIcon = styled(PlusIcon, {
  height: '18px',
  width: '18px',
  fill: '$black',
});

const QuantityInput = styled('input', {
  backgroundColor: 'inherit',
  opacity: 0.7,
  maxW: 17.5,
  textAlign: 'center',
  MozAppearance: 'textfield',
  '&::-webkit-outer-spin-button': {
    WebkitAppearance: 'none',
    m: 0,
  },
  '&::-webkit-inner-spin-button': {
    WebkitAppearance: 'none',
    m: 0,
  },
});

const AddToCartButton = styled(`button`, {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  h: 'auto',
  px: 3,
  fs: 10,
  fontWeight: 'lighter',
  transition: '$200',
  my: 2,
  border: 'solid',
  bw: 0.25,
  color: '$primary',
  '&:nth-child(n)[data-disabled="true"]': {
    opacity: 0.5,
  },
});

const StyledMinusIcon = styled(MinusIcon, {
  h: 4.5,
  w: 4.5,
  fill: '$Black',
});

const StyledLoadingCircle = styled(LoadingCircle, {
  w: 9,
  my: -4,
  storke: '$primary',
});

const AddToCartErrorContainer = styled(`div`, {
  backgroundcolor: '$errorPrimary',
  mb: 4.5,
  p: 3.5,
  '@mediaMaxSmall': {
    mb: 4,
  },
});

const AddToCartError = styled(`span`, {
  display: 'flex',
  alignItems: 'center',
  color: '$errorText',
  fontSize: 3.5,
  lineHeight: '$lh125',
  letterSpacing: '$ls07',
});

const iconCss = {
  w: 4,
  h: 5,
  mr: 2,
};

export default SingleBatchOrder;
