import { useState } from 'react';
import useCurrentPage from '../../Shared/Hooks/useCurrentPage';
import { styled } from '../../stitches.config';
import { useAppSettingsData } from '../../Shared/Providers/AppSettingsProvider';
import KexInput from '../../Shared/Input/KexInput';
import { KexInputValidation } from '../../Shared/Input/KexInputValidation';
import PageModelBase from '../../Shared/Models/PageModelBase.interface';
import {
  ExclamationMarkIcon,
  LoadingCircle,
  CirclePlusIcon,
  AttentionIcon,
} from '../../Atoms/Icons';
import {
  AddItemsToCart,
  CheckItems,
} from '../../BatchOrderPage/Models/BatchOrder';
import { useTranslationData } from '../../Shared/Providers/TranslationProvider';

function SingleBatchOrder() {
  const [error, setError] = useState(false);
  const [validationMessage, setValidationMessage] = useState<string>('');
  const [btnEnabled, setBtnEnabled] = useState<boolean>(false);
  const [productExist, setProductExist] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [disabled, setDisabled] = useState(false);
  const [code, setCode] = useState<string>('');
  const [quantity, setQuantity] = useState<string>('1');
  const [addToCartErrorMessage, setErrorMessage] = useState<string>('');

  const { languageRoute } = useAppSettingsData();

  const {
    'batchOrder/inputLabel': inputLabel,
    'batchOrder/textAreaPlaceholder': textAreaPlaceholder,
    'batchOrder/addItems': addItemsLabel,
    'batchOrder/inputValidationRequired': inputValidationRequired,
    'batchOrder/errorMessage': errorMessage,
  } = useTranslationData();

  const { channelId } = useCurrentPage<PageModelBase>();
  const onInputsValid = () => setBtnEnabled(true);
  const onInputsInvalid = () => setBtnEnabled(false);

  const handleSetCode = (value: string) => {
    setProductExist(false);
    setErrorMessage('');
    setCode(value);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (disabled) return;

    const data: any[] = [];
    const codeList = code.split('\n');

    codeList.map((code: string) => {
      const cList = code.split(',');
      if (!isNaN(parseInt(cList[1]))) {
        data.push({
          code: cList[0],
          quantity: parseInt(cList[1]),
        });
      }
    });

    setIsLoading(true);
    await AddItemsToCart(channelId, languageRoute, data);
    setIsLoading(false);
  };

  const handleCheckItems = async () => {
    if (code.length > 0) {
      setErrorMessage('');

      let codeList = code.split('\n');

      codeList = codeList.map((c: string) => {
        const index = c.indexOf(',');
        if (index >= 0) return (c = c.substring(0, index));

        return c;
      });

      const res = await CheckItems(
        channelId,
        languageRoute,
        codeList,
        setDisabled
      );

      if (res.foundItems.length > 0 && res.notFoundItems.length === 0) {
        setProductExist(true);
      } else {
        setProductExist(false);
        setErrorMessage(
          `${errorMessage.replace('{0}', res.notFoundItems.join(', '))}`
        );
      }
    }
  };

  const isNotValid = disabled || !btnEnabled || !productExist;

  return (
    <>
      <ArticleArea>
        <InputArea data-disabled={isLoading}>
          {error && (
            <ValidationMessage>
              <ExclamationMarkIcon css={IconStyleError} />{' '}
              <ErrorMessage>{validationMessage}</ErrorMessage>
            </ValidationMessage>
          )}
          <KexInputValidation
            onInputsValid={onInputsValid}
            onInputsInvalid={onInputsInvalid}
          >
            <StyledKexInput
              type="textarea"
              name="BatchOrderItem"
              title={inputLabel}
              placeholder={textAreaPlaceholder}
              value={code}
              onChange={handleSetCode}
              onBlur={handleCheckItems}
              validation={{
                required: true,
                errorMessage: inputValidationRequired,
              }}
            />
          </KexInputValidation>
        </InputArea>

        <AddToCartContainer data-disabled={isNotValid}>
          <AddToCartButton disabled={isNotValid} onClick={handleSubmit}>
            <>
              {isLoading ? (
                <StyledLoadingCircle css={iconCss} isLoading={isLoading} />
              ) : (
                <CirclePlusIcon css={iconCss} />
              )}
              {addItemsLabel}
            </>
          </AddToCartButton>
        </AddToCartContainer>
      </ArticleArea>

      {addToCartErrorMessage && (
        <AddToCartErrorContainer>
          <AddToCartError>
            <AttentionIcon color="error" css={iconCss} />
            {addToCartErrorMessage}
          </AddToCartError>
        </AddToCartErrorContainer>
      )}
    </>
  );
}

const DisabledStyle = {
  '&:nth-child(n)[data-disabled="true"]': {
    opacity: 0.5,
    pointerEvents: 'none',
  },
};

const ValidationMessage = styled(`div`, {
  fs: 8,
  fontWeight: '$fw400',
  lineHeight: '$lh1625',
  backgroundColor: '$errorPrimary',
  color: '$errorText',
  px: 6,
  py: 6,
  my: 6,
  display: 'flex',
});

const IconStyleError = {
  w: 4,
  h: 4,
  mr: 2,
  mt: 1,
};

const StyledKexInput = styled(KexInput, {
  backgroundColor: '$textSecondary',
});

const ErrorMessage = styled(`p`, {
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: '0%',
});

const ArticleArea = styled(`div`, {
  display: 'flex',
  mb: 4.5,
  '@mediaMaxSmall': {
    mt: 4,
  },
});

const InputArea = styled(`div`, {
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: '0%',
  mr: 4,
});

const AddToCartContainer = styled(`div`, {
  display: 'flex',
  ...DisabledStyle,
});

const AddToCartButton = styled(`button`, {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  h: 11,
  px: 3,
  fontSize: 10,
  fontWeight: 'lighter',
  transition: '$200',
  my: 2,
  border: 'solid',
  bw: 0.25,
  borderColor: '$primary',
  '&:nth-child(n)[data-disabled="true"]': {
    opacity: 0.5,
  },
});

const StyledLoadingCircle = styled(LoadingCircle, {
  w: 9,
  my: -1,
  stroke: '$primary',
});

const AddToCartErrorContainer = styled(`div`, {
  backgroundColor: '$errorPrimary',
  mb: 4.5,
  p: 3.5,
  '@mediaMaxSmall': {
    mb: 4,
  },
});

const AddToCartError = styled(`span`, {
  display: 'flex',
  alignItems: 'center',
  color: '$errorText',
  fontSize: 3.5,
  lineHeight: '$lh125',
  letterSpacing: '$ls113',
});

const iconCss = {
  w: 4,
  h: 5,
  mr: 2,
};

export default SingleBatchOrder;
